import React from 'react';
import { formatMessage } from '@/locales';
import GameCard from '../components/game-card/game-card.component';
import './game-content-1.view.less';

import { GAME_LIST } from '@/constants';

const GameContent1View = () => {
    const availableList = GAME_LIST.filter(i => i.isShow);
    return (
        <div className="game-content-1-view">
            {availableList.map(i => (
                <div className="game-card-animate-wrap">
                    <GameCard
                        title={formatMessage(i.title)}
                        imageRender={() => {
                            return (
                                <div className='background-container'>
                                    <picture>
                                        <source
                                            srcSet={i.mobileImg}
                                            media="(max-width: 768px)"
                                        />
                                        <img
                                            className="background-image"
                                            src={i.pcImg}
                                            alt=""
                                        />
                                    </picture>
                                    <source
                                        srcSet={i.mobileIcon}
                                        media="(max-width: 768px)"
                                    />
                                </div>
                            );
                        }}
                        icon={i.pcIcon}
                        desc={formatMessage(i.desc)}
                        id={i.id}
                        type={i.type}
                        cardType={i.cardType}
                        buttonText={formatMessage(i.buttonText)}
                    />
                </div>
            ))}
        </div>
    );
};

export default GameContent1View;
