export default {
    // banner
    'home.banner.description': 'Make Fun, Make Sense',
    // souls
    'home.content00.title': '光之境',
    'home.content00.description': `快来参加这场正义与邪恶的史诗般的古老战斗吧。 探索世界，赢得战斗并拯救世界。`,
    'home.content00.buttonText': '查看游戏',
    // capybara
    'home.content.capybara.title': '卡皮巴拉 GO!',
    'home.content.capybara.description': `喜欢水豚吗？在最狂野的roguelike RPG中扮演一只水豚吧！`,
    'home.content.capybara.buttonText': '查看游戏',
    // archero2
    'home.content.archero2.title': '弓箭传说2',
    'home.content.archero2.description': `肉鸽体验2.0，谱写新的传说。更沉浸的游戏体验，更极致的游戏挑战！`,
    'home.content.archero2.buttonText': '查看游戏',
    // content 0
    'home.content.survivor.title': '弹壳特攻队',
    'home.content.survivor.description': `为了生存！燃烧你的手指和千万怪兽军团！一款Roguelike、求生、割草完美结合的游戏不仅有末路求生的刺激爽快感，Roguelike超新鲜感体验，还融入了更多特别有趣玩法。`,
    'home.content.survivor.buttonText': '查看游戏',
    // content 1
    'home.content1.title': '我们的理念',
    'home.content1.description': `游戏是一种能给人带来快乐的兴趣。无论游戏的类型，平台的大小，通关的快慢，作为玩家，只要你沉浸其中，就可以从繁杂中获得宁静，从忙碌中得到快乐。这就是HABBY全力以赴的梦想：用乐趣将所有玩家联合起来，创造一个带给人快乐的HABBY游戏世界！`,
    'home.content1.buttonText': '了解更多',
    // content 2
    'home.content.archero.name': '弓箭传说',
    'home.content.archero.description': `易上手，难精通。这个独特的手游爆款是个传统玩法简化了 roguelike 的体验单手操控、接近无尽的关卡；射射射！`,
    'home.content.archero.buttonText': '查看游戏',
    // content 3
    'home.content3.title': '海彼文化',
    'home.content3.description': `我们致力于给全球玩家带来崭新的游戏体验！快加入我们，共同开启一段独特的游戏创新之旅。`,
    'home.content3.buttonText': '查看职位',
    // content 4
    'home.content4.title': '全球发行',
    'home.content4.description': `我们一直在寻找独特的、原创的游戏创意。我们热爱与世界各地的游戏团队合作，一起创作出最棒的游戏。如果你有游戏或创意，请与我们联系！`,
    'home.content4.buttonText': '联系我们'
};
