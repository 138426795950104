/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { formatMessage } from '@/locales';
import './tool.less';

class Tool extends React.Component {
    constructor(props) {
        super(props);

    }

    handleDownload = url => {
        if (url) {
            window.open(url);
        }
    };

    render() {
        const { appleurl, googleplayurl, detailPageId } = this.props;
        return (
            <div className="tools">
                <div
                    className="downloadBtn"
                    onClick={() => {
                        this.handleDownload(appleurl);
                    }}
                >
                    <img
                        alt="applestoredownload"
                        className="downloadBtn"
                        src={require('./img/appstore_button_black@2x.png')}
                    />
                </div>
                <div
                    className="downloadBtn"
                    onClick={() => {
                        this.handleDownload(googleplayurl);
                    }}
                >
                    <img
                        alt="googleplaydownload"
                        src={require('./img/googleplay_button_black@2x.png')}
                    />
                </div>
                <Link to={`/game/detail/${detailPageId}`}>
                    <div className="detailBtn">
                        <img
                            alt="detailarrow"
                            className="detail-arrow"
                            src={require('./img/arrow@2x.png')}
                        />
                        <label className="detail-label">
                            { formatMessage({ id: `home.content.${detailPageId}.buttonText` }) }
                        </label>
                    </div>
                </Link>
            </div>
        );
    }
}

export default Tool;
