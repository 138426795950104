export default {
    // banner
    'game.banner.title': 'We Are A Gaming Studio!',
    'game.banner.description': `Habby is a game studio dedicated to creating timeless interactive entertainment that inspires human connections worldwide.`,
    // content 1
    'game.content1.game1.title': 'Archero',
    'game.content1.game1.desc': `BE A HERO!`,
    'game.content1.game1.buttonText': 'Read More',

    'game.content1.game2.title': 'Penguin Isle',
    'game.content1.game2.desc': `CHILL WITH LOVELY PENGUINS!`,
    'game.content1.game2.buttonText': 'Read More',

    'game.content1.game3.title': 'Punball',
    'game.content1.game3.desc': `AIM, SHOOT, BOUNCE!`,
    'game.content1.game3.buttonText': 'Read More',

    'game.content1.game4.title': 'Slidey',
    'game.content1.game4.desc': `INNOVATIVE PUZZLE GAME!`,
    'game.content1.game4.buttonText': 'Read More',

    'game.content1.game5.title': 'Super Hit Baseball',
    'game.content1.game5.desc': `MULTIPLAYER BASEBALL MATCHES!`,
    'game.content1.game5.buttonText': 'Read More',

    'game.content1.game6.title': 'Spin Rhythm',
    'game.content1.game6.desc': `FUNKY BEATS & CHALLENGES`,
    'game.content1.game6.buttonText': 'Read More',

    'game.content1.game7.title': 'Flaming Core',
    'game.content1.game7.desc': `BULLET-TIME ACTION!`,
    'game.content1.game7.buttonText': 'Read More',

    'game.content1.game8.title': 'Survivor.io',
    'game.content1.game8.desc': `SURVIVE THE FUN!`,
    'game.content1.game8.buttonText': 'Read More',

    'game.content1.game9.title': 'Kinja Run',
    'game.content1.game9.desc': `KITTY NINJA RUN!`,
    'game.content1.game9.buttonText': 'Read More',

    'game.content1.game10.title': 'SSSnaker',
    'game.content1.game10.desc': `SLITHER, SNACK, SHOOT!`,
    'game.content1.game10.buttonText': 'Read More',

    'game.content1.game11.title': 'Souls',
    'game.content1.game11.desc': `EPIC RPG ADVENTURE`,
    'game.content1.game11.buttonText': 'Read More',

    'game.content1.game13.title': 'Fishdom',
    'game.content1.game13.desc': `Dive into an underwater world of match-3 fun!。`,
    'game.content1.game13.buttonText': 'Read More',

    'game.content1.game14.title': 'Capybara Go!',
    'game.content1.game14.desc': `Love capybaras so much you want to play as one?`,
    'game.content1.game14.buttonText': 'Read More',

    'game.content1.game15.title': 'Archero2',
    'game.content1.game15.desc': `Join 200 million players in unlocking the memories of the archer!`,
    'game.content1.game15.buttonText': 'Read More',

    'game.content1.game12.title': 'Coming Soon',

    // content 2
    'game.content2.title': 'What We’re Looking For:',
    'game.content2.list': [
        'Mobile titles to publish',
        'Unique game ideas',
        'Great long-term retention',
        'Studios to invest'
    ],
    'game.content2.buttonText': 'Let’s Talk'
};
