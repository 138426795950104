/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import './button.less';

export const Button = ({ className, style, children, ...otherProps }) => {
    return (
        <button
            type="button"
            className={classnames('button', className)}
            style={style}
            {...otherProps}
        >
            <img
                alt="detailarrow"
                className="detail-arrow"
                src={require('./img/arrow@2x.png')}
            />
            <span className="text"> {children || 'Read More'}</span>
        </button>
    );
};
