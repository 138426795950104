import React, { useRef, useEffect } from 'react';
import { Header, Footer } from '@/components';
import CareerBannerView from './career-banner/career-banner.view';
import CareerContent1 from './career-content-1/career-content-1.view';
import CareerContent2 from './career-content-2/career-content-2.view';
import CareerContent3 from './career-content-3/career-content-3.view';
import CareerContent4 from './career-content-4/career-content-4.view';
import useSmoothScroll from '@/hooks/useSmoothScroll';
import useScrollToTop from '@/hooks/useScrollToTop';

const CareerView = () => {
    const containerRef = useRef(null);
    const smoothScrollRef = useRef(null);

    // 使用自定义 Hook，返回是否为移动设备
    const isMobile = useSmoothScroll(smoothScrollRef);
    useScrollToTop();

    useEffect(() => {
        const updateHeight = () => {
            if (!smoothScrollRef.current || isMobile) return;
            
            requestAnimationFrame(() => {
                const container = smoothScrollRef.current;
                const rect = container.getBoundingClientRect();
                const totalHeight = Math.ceil(rect.height) - 2;
                
                document.body.style.height = `${totalHeight}px`;
            });
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        
        const resizeObserver = new ResizeObserver(updateHeight);
        if (smoothScrollRef.current) {
            resizeObserver.observe(smoothScrollRef.current);
        }

        return () => {
            window.removeEventListener('resize', updateHeight);
            resizeObserver.disconnect();
            document.body.style.height = '';
        };
    }, [isMobile]);

    return (
        <>
            <Header />
            <div
                ref={containerRef}
                style={{
                    position: isMobile ? 'relative' : 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    willChange: isMobile ? 'auto' : 'transform',
                    backfaceVisibility: isMobile ? 'visible' : 'hidden',
                    perspective: isMobile ? 'none' : 1000
                }}
            >
                <div ref={smoothScrollRef} className="default-view">
                    <CareerBannerView />
                    <CareerContent1 />
                    <CareerContent2 />
                    <CareerContent3 />
                    <CareerContent4 />
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default CareerView;
