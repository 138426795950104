export const ABOUT_TIME_LINE = [
    {
        image: require('@/views/about/about-content-2/img/picture0@2x.png'),
        time: 'about.content2.1.date',
        title: 'about.content2.1.title',
        content: 'about.content2.1.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture1@2x.png'),
        time: 'about.content2.2.date',
        title: 'about.content2.2.title',
        content: 'about.content2.2.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture2@2x.png'),
        time: 'about.content2.3.date',
        title: 'about.content2.3.title',
        content: 'about.content2.3.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture3@2x.png'),
        time: 'about.content2.4.date',
        title: 'about.content2.4.title',
        content: 'about.content2.4.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture4@2x.png'),
        time: 'about.content2.5.date',
        title: 'about.content2.5.title',
        content: 'about.content2.5.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture5@2x.png'),
        time: 'about.content2.6.date',
        title: 'about.content2.6.title',
        content: 'about.content2.6.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture6@2x.png'),
        time: 'about.content2.7.date',
        title: 'about.content2.7.title',
        content: 'about.content2.7.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture7@2x.png'),
        time: 'about.content2.8.date',
        title: 'about.content2.8.title',
        content: 'about.content2.8.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture8@2x.png'),
        time: 'about.content2.9.date',
        title: 'about.content2.9.title',
        content: 'about.content2.9.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture9@2x.png'),
        time: 'about.content2.10.date',
        title: 'about.content2.10.title',
        content: 'about.content2.10.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture10@2x.png'),
        time: 'about.content2.11.date',
        title: 'about.content2.11.title',
        content: 'about.content2.11.desc'
    },
    {
        image: require('@/views/about/about-content-2/img/picture11@2x.png'),
        time: 'about.content2.12.date',
        title: 'about.content2.12.title',
        content: 'about.content2.12.desc'
    }
];
