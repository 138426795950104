/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { Fragment } from 'react';
import { formatMessage } from '@/locales';
import PointerImage from './img/point@2x.png';
import LineEllipsisImage from './img/line_ellipsis@2x.png';
import { ABOUT_TIME_LINE } from '@/constants/about';
import './about-content-2.view.less';

const AboutContent2View = () => {
    return (
        <div className="about-content-2-view">
            <div className="content">
                <ul className="time-line-list">
                    <li className="line-ellipsis">
                        <img src={LineEllipsisImage} alt="" />
                    </li>
                    {ABOUT_TIME_LINE.map((item, index) => {
                        const { image, time, title, content } = item;
                        const className =
                            index % 2 > 0 ? 'layout-left' : 'layout-right';

                        return (
                            <Fragment key={index}>
                                <li className={`time-line-item ${className}`}>
                                    <div className="left">
                                        <div className="image-wrapper">
                                            <img src={image} alt="" />
                                        </div>
                                        <div className="time-text">
                                            {formatMessage(time)}
                                        </div>
                                    </div>
                                    <div className="line-block">
                                        <div className="line-pointer">
                                            <img src={PointerImage} alt="" />
                                        </div>
                                        <div className="line" />
                                    </div>
                                    <div className="content-main">
                                        <h4 className="title">
                                            {formatMessage(title)}
                                        </h4>
                                        <p className="desc">
                                            {formatMessage(content)}
                                        </p>
                                    </div>
                                </li>
                                <li className={`time-line-item ${className}`}>
                                    <div className="left" />
                                    <div className="line-block">
                                        <div className="line" />
                                    </div>
                                    <div className="content-main" />
                                </li>
                            </Fragment>
                        );
                    })}
                    <li className="line-ellipsis">
                        <img src={LineEllipsisImage} alt="" />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AboutContent2View;
