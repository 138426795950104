import { useEffect, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

/**
 * 平滑滚动效果的自定义 Hook
 * @param {Object} ref - 需要应用滚动效果的元素的 ref
 * @param {number} [ease=0.1] - 缓动系数，控制滚动的平滑程度
 */
const useSmoothScroll = (ref, ease = 0.1) => {
    const isMobile = useCallback(() => {
        return window.innerWidth <= 768;
    }, []);

    const update = useCallback((smoothScroll) => {
        if (!smoothScroll.target || isMobile()) return;

        const resized = smoothScroll.resizeRequest > 0;
        
        if (resized) {
            smoothScroll.resizeRequest = 0;
        }

        const currentTime = performance.now();
        const deltaTime = (currentTime - smoothScroll.lastTime) / 16.67;
        smoothScroll.lastTime = currentTime;

        const lerp = (start, end, t) => start + (end - start) * Math.min(t * deltaTime, 1);
        
        smoothScroll.y = lerp(
            smoothScroll.y,
            smoothScroll.endY,
            smoothScroll.ease
        );

        const roundedY = Math.round(smoothScroll.y * 100) / 100;
        gsap.set(smoothScroll.target, {
            force3D: true,
            transform: `translate3d(0, ${-roundedY}px, 0)`
        });

        const diff = Math.abs(smoothScroll.endY - smoothScroll.y);
        const threshold = 0.05;
        
        if (diff > threshold) {
            smoothScroll.rafId = requestAnimationFrame(() => update(smoothScroll));
        } else {
            if (diff !== 0) {
                smoothScroll.y = smoothScroll.endY;
                gsap.set(smoothScroll.target, {
                    force3D: true,
                    transform: `translate3d(0, ${-smoothScroll.endY}px, 0)`
                });
            }
            smoothScroll.rafId = null;
        }
    }, [isMobile]);

    useEffect(() => {
        const smoothScroll = {
            target: ref.current,
            ease,
            endY: 0,
            y: 0,
            resizeRequest: 1,
            scrollRequest: 0,
            rafId: null,
            lastTime: performance.now()
        };

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        smoothScroll.isVisible = true;
                    } else {
                        smoothScroll.isVisible = false;
                    }
                });
            },
            { threshold: 0 }
        );

        if (smoothScroll.target) {
            observer.observe(smoothScroll.target);
        }

        const onScroll = () => {
            if (isMobile()) return;
            if (!smoothScroll.isVisible) return;
            
            smoothScroll.endY = window.pageYOffset;
            if (!smoothScroll.rafId) {
                smoothScroll.rafId = requestAnimationFrame(() => update(smoothScroll));
            }
        };

        const onResize = () => {
            if (isMobile()) {
                if (smoothScroll.rafId) {
                    cancelAnimationFrame(smoothScroll.rafId);
                    smoothScroll.rafId = null;
                }
                if (smoothScroll.target) {
                    gsap.set(smoothScroll.target, { clearProps: "all" });
                }
                return;
            }
            
            smoothScroll.resizeRequest += 1;
            if (!smoothScroll.rafId) {
                smoothScroll.rafId = requestAnimationFrame(() => update(smoothScroll));
            }
        };

        window.addEventListener('scroll', onScroll, { passive: true });
        window.addEventListener('resize', onResize, { passive: true });

        return () => {
            window.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResize);
            observer.disconnect();
            
            if (smoothScroll.rafId) {
                cancelAnimationFrame(smoothScroll.rafId);
            }
            if (smoothScroll.target) {
                gsap.set(smoothScroll.target, { clearProps: "all" });
            }
        };
    }, [ref, ease, update, isMobile]);

    return isMobile();
};

export default useSmoothScroll;
