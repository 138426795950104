export default {
    // banner
    'game.banner.title': '我们是游戏创造者！',
    'game.banner.description': `海彼是一个游戏创造者，我们希望用游戏乐趣将所有玩家联合起来，创造出一个带给人快乐的海彼游戏世界！`,
    // content 1
    'game.content1.game1.title': '弓箭传说',
    'game.content1.game1.desc': `战斗吧弓箭英雄！`,
    'game.content1.game1.buttonText': '了解更多',
    'game.content1.game2.title': '企鹅之岛',
    'game.content1.game2.desc': `与企鹅一起放松身心！`,
    'game.content1.game2.buttonText': '了解更多',
    'game.content1.game3.title': '砰砰法师',
    'game.content1.game3.desc': `瞄准！射击！跳跃！`,
    'game.content1.game3.buttonText': '了解更多',
    'game.content1.game4.title': '萨满爱消除',
    'game.content1.game4.desc': `消除游戏的颠覆之作！`,
    'game.content1.game4.buttonText': '了解更多',

    'game.content1.game5.title': '超级棒球',
    'game.content1.game5.desc': `超爽的棒球比赛`,
    'game.content1.game5.buttonText': '了解更多',

    'game.content1.game6.title': '旋转节奏',
    'game.content1.game6.desc': `炫酷的音乐挑战`,
    'game.content1.game6.buttonText': '了解更多',

    'game.content1.game7.title': '赤核',
    'game.content1.game7.desc': `欢迎进入黑客的世界!`,
    'game.content1.game7.buttonText': '了解更多',

    'game.content1.game8.title': '弹壳特攻队',
    'game.content1.game8.desc': `活屍大軍的末日`,
    'game.content1.game8.buttonText': '了解更多',

    'game.content1.game9.title': '酷喵跑酷',
    'game.content1.game9.desc': `忍者酷喵 衝！衝！衝！`,
    'game.content1.game9.buttonText': '了解更多',

    'game.content1.game10.title': '蛇蛇贪吃',
    'game.content1.game10.desc': `蛇蛇贪吃趴趴走，Rogue升级打怪兽！`,
    'game.content1.game10.buttonText': '了解更多',

    'game.content1.game11.title': '光之境',
    'game.content1.game11.desc': `史诗级RPG冒险`,
    'game.content1.game11.buttonText': '了解更多',

    'game.content1.game13.title': '梦幻水族箱',
    'game.content1.game13.desc': `探索神秘海底世界，享受无尽三消乐趣`,
    'game.content1.game13.buttonText': '了解更多',

    'game.content1.game14.title': '卡皮巴拉 Go!',
    'game.content1.game14.desc': `你想成为一只卡皮巴拉吗？`,
    'game.content1.game14.buttonText': '了解更多',

    'game.content1.game15.title': '弓箭传说2',
    'game.content1.game15.desc': `和亿万玩家一起，启动弓箭手回忆！`,
    'game.content1.game15.buttonText': '了解更多',

    'game.content1.game12.title': '即将上线',

    // content 2
    'game.content2.title': '我们寻求的合作:',
    'game.content2.list': [
        '我们可以帮您的游戏在全球发行',
        '我们可以投资您的团队',
        '我们希望寻找到独特的游戏想法和生命周期长的游戏'
    ],
    'game.content2.buttonText': '联系我们'
};
