import React from 'react';
import { Link } from 'react-router-dom';
import { Button, SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import './home-content-culture.view.less';

const HomeContent3View = () => {
    return (
        <SectionContainer
            className="home-content-culture-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/home/culture/home_page_philosophy_background@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/home/culture/background.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h1 className="title">
                    {formatMessage({ id: 'home.content3.title' })}
                </h1>
                <p className="desc">
                    {formatMessage({ id: 'home.content3.description' })}
                </p>
                <Link to="/career#jobs">
                    <Button>
                        {formatMessage({ id: 'home.content3.buttonText' })}
                    </Button>
                </Link>
            </div>
        </SectionContainer>
    );
};

export default HomeContent3View;
