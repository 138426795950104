import React, { useEffect } from 'react';
import { Footer } from '@/components';
import GameDetailBannerView from '../../game-detail-banner/game-detail-banner.view';

import GameDetailContent1 from '../../game-detail-content-1/game-detail-content-1.view';
import GameDetailContent2 from '../../game-detail-content-2/game-detail-content-2.view';
import GameDetailContent3 from '../../game-detail-content-3/game-detail-content-3.view';

const PicTextGameDetail = props => {
    const { data, localId, id } = props || {};
    console.log(data);
    const { bannerImage, game = {}, contents = [], gameDisplay = {} } = data || {};


    return (
        <div className="default-view">
            <GameDetailBannerView
                imageRender={() => {
                    return (
                        <picture>
                            {Object.entries(bannerImage).map(([key, value]) => {
                                if (key === 'default') {
                                    return (
                                        <img
                                            key={key}
                                            className="background-image"
                                            src={value}
                                            alt=""
                                        />
                                    );
                                }
                                return (
                                    <source
                                        key={key}
                                        srcSet={value}
                                        media={`(max-width: ${key}px)`}
                                    />
                                );
                            })}
                        </picture>
                    );
                }}
                localId={localId}
            />
            <GameDetailContent1 data={game} localId={localId} id={id} />
            {contents.map((contentItem, index) => {
                return (
                    <GameDetailContent2
                        key={index}
                        data={contentItem}
                        localId={localId}
                    />
                );
            })}
            <GameDetailContent3 data={gameDisplay} localId={localId} />
            <Footer />
        </div>
    );
};

export default PicTextGameDetail;
