/* eslint-disable react/prop-types */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, ShadowCard } from '@/components';
import './game-card.component.less';

const GameCardComponent = ({
    image,
    imageRender,
    title,
    desc,
    id,
    type,
    cardType,
    buttonText,
    icon
}) => {
    const history = useHistory();
    const imageRenderHandler = () => {
        if (typeof imageRender === 'function') {
            return imageRender();
        }
        if (image) {
            return (
                <div>
                    <img src={image} alt="" />
                </div>
            );
        }
        return null;
    };

    function handleJumpDetail() {
        if (type === 'coming') {
            return;
        }
        if (id === 'fishdom') {
            window.open('https://shuizuxiang.habby.cn/');
        } else {
            history.push(`/game/detail/${id}`);
        }
    }

    if (cardType === 'comingsoon') {
        return (
            <ShadowCard className="game-card-component">
                <h3 className="title">{title}</h3>
            </ShadowCard>
        );
    }
    return (
        <ShadowCard
            className="game-card-component"
            onClick={handleJumpDetail.bind(this, id)}
        >
            <div className="image-wrapper">
                {imageRenderHandler()}
            </div>
            <img className="icon" src={icon} alt="" />
            <h3 className="title">{title}</h3>
            <p className="desc">{desc}</p>
            <Button className={`game-card-button ${type === 'coming' ? 'button-disabled' : ''}`}>
                {buttonText}
            </Button>
        </ShadowCard>
    );
};

export default GameCardComponent;
