/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { LocalStorageService } from '@/services';
import { formatMessage, setLanguage } from '@/locales';
import HabbyLogoImage from './img/habby-logo@2x.png';
import './header.less';

export const Header = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [collapse, setCollapse] = useState(true);
    const localId = LocalStorageService.get('localId') || 'en-US';

    const [isShow, setIsShow] = useState(true);

    let lastScrollTop = 0;
    const handleScroll = () => {
        if (document.documentElement.clientWidth <= 768) {
            return;
        }
        const clientHeight = document.documentElement.clientHeight; // 可视区域高度
        const scrollTop = document.documentElement.scrollTop; // 滚动条滚动高度
        const scrollHeight = document.documentElement.scrollHeight; //
        // console.log("scrollTop", scrollTop, 'lastScrollY', lastScrollTop, 'clientHeight', clientHeight, 'scrollHeight', scrollHeight);
        if (scrollTop > lastScrollTop) {
            setIsShow(false);
        } else if (scrollTop < lastScrollTop) {
            setIsShow(true);
        }
        lastScrollTop = document.documentElement.scrollTop;
        // 判断是否滚动到底部
        if (scrollTop + clientHeight === scrollHeight) {
            console.log('滚动到底部');
        }
    };

    useEffect(() => {
        // 监听
        window.addEventListener('scroll', handleScroll, true);
        // 销毁
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const setLanguageHandler = newLocalId => {
        setLanguage(newLocalId);
        history.replace(pathname, {
            localId
        });
        LocalStorageService.set('localId', newLocalId);
    };

    const toggleLocalId = () => {
        if (localId === 'en-US') {
            setLanguageHandler('zh-CN');
        } else {
            setLanguageHandler('en-US');
        }
        setCollapse(!collapse);
    };

    return (
        <header className={`header ${isShow ? 'show' : 'hide'}`}>
            <div className="logo-wrapper">
                <Link className="logo" to="/">
                    <img src={HabbyLogoImage} alt="" />
                </Link>
            </div>
            <div className="header-content">
                <span
                    className="nav-trigger"
                    role="button"
                    tabIndex="-1"
                    data-collapse={collapse}
                    onClick={() => {
                        setCollapse(!collapse);
                    }}
                >
                    {collapse ? (
                        <span
                            role="button"
                            tabIndex="-1"
                            aria-label="menu"
                            className="anticon anticon-menu"
                        >
                            <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="menu"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
                            </svg>
                        </span>
                    ) : (
                        <span
                            role="button"
                            tabIndex="-1"
                            aria-label="close"
                            className="anticon anticon-close"
                        >
                            <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="close"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
                            </svg>
                        </span>
                    )}
                </span>
                <ul className="nav-list">
                    <li className="nav-item">
                        <NavLink exact to="/" activeClassName="active">
                            {formatMessage('nav.home')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/game" activeClassName="active">
                            {formatMessage('nav.game')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/about" activeClassName="active">
                            {formatMessage('nav.about')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/career" activeClassName="active">
                            {formatMessage('nav.career')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/contact" activeClassName="active">
                            {formatMessage('nav.contact')}
                        </NavLink>
                    </li>
                    <li
                        className="nav-item toggle-local"
                        onClick={toggleLocalId}
                    >
                        {localId === 'en-US' ? (
                            <a>切换中文</a>
                        ) : (
                            <a>Change to EN</a>
                        )}
                    </li>
                </ul>
            </div>
        </header>
    );
};
