export default {
    // banner
    'home.banner.description': 'Make Fun, Make Sense',
    // capybara
    'home.content.capybara.title': 'Capybara Go',
    'home.content.capybara.description': `Love capybaras so much you want to play as one? Get ready to embark on a whimsical adventure with a bunch of furry, roguelike adventure RPG!`,
    'home.content.capybara.buttonText': 'View',
    // archero2
    'home.content.archero2.title': 'Archero2',
    'home.content.archero2.description': `Experience Roguelike 2.0, Be Your Own Hero! Extremely Immersive Gameplay, Extremely Challenging Fun!`,
    'home.content.archero2.buttonText': 'View',
    // souls
    'home.content00.title': 'Souls',
    'home.content00.description': `Come and take part in this ancient battle in an epic fight in good versus evil. Explore the world, win the battle and save the world.`,
    'home.content00.buttonText': 'View',
    // content 0
    'home.content.survivor.title': 'Survivor',
    'home.content.survivor.description': `As a human warrior with unlimited potential, you and other survivors will have to pick up your weapons and battle these evil and dangerous zombies!`,
    'home.content.survivor.buttonText': 'View',
    // content 1
    'home.content1.title': 'Our Philosophy',
    'home.content1.description': `Gaming, as we see it, is a hobby that makes you happy. Doesn't matter if you're good or bad at it, what games you play, which platforms you prefer. As long as you enjoy your time, and it makes you forget your troubles for the day, even for a moment. Habby's all about that: dedicated to bringing fun and interaction to everyone, connecting all of us regardless of age, culture or background.`,
    'home.content1.buttonText': 'Read More',
    // content 2
    'home.content.archero.name': 'Archero',
    'home.content.archero.description': `Easy to play, hard to master.  This unique hit is a reinvention of classic gameplay bringing a casual touch to roguelike games on mobile.`,
    'home.content.archero.buttonText': 'View',
    // content 3
    'home.content3.title': 'Habby Culture',
    'home.content3.description': `At Habby, we're committed to bringing change to all our players. Join us on this journey to make a difference in the industry.`,
    'home.content3.buttonText': 'View Jobs',
    // content 4
    'home.content4.title': 'Game Publishing',
    'home.content4.description': `Have a game or an idea? Talk to us! We're constantly on the hunt for unique and original game ideas. We love working with studios worldwide to create the best games. Submit your game now!`,
    'home.content4.buttonText': 'Contact Us'
};
