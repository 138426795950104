import React, { useRef, useEffect } from 'react';
import { Header, Footer } from '@/components';
import AboutBannerView from './about-banner/about-banner.view';
import AboutContent1View from './about-content-1/about-content-1.view';
import AboutContent2View from './about-content-2/about-content-2.view';
import AboutContent22View from './about-content-2-2/about-content-2-2.view';
import AboutContent3View from './about-content-3/about-content-3.view';
import useSmoothScroll from '@/hooks/useSmoothScroll';
import useScrollToTop from '@/hooks/useScrollToTop';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const AboutView = () => {
    const containerRef = useRef(null);
    const smoothScrollRef = useRef(null);

    // 使用自定义 Hook，返回是否为移动设备
    const isMobile = useSmoothScroll(smoothScrollRef);
    useScrollToTop();

    useEffect(() => {
        const updateHeight = () => {
            if (!smoothScrollRef.current || isMobile) return;
            
            requestAnimationFrame(() => {
                const container = smoothScrollRef.current;
                const rect = container.getBoundingClientRect();
                const totalHeight = Math.ceil(rect.height) - 2;
                
                document.body.style.height = `${totalHeight}px`;
            });
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        
        const resizeObserver = new ResizeObserver(updateHeight);
        if (smoothScrollRef.current) {
            resizeObserver.observe(smoothScrollRef.current);
        }

        return () => {
            window.removeEventListener('resize', updateHeight);
            resizeObserver.disconnect();
            document.body.style.height = '';
        };
    }, [isMobile]);

    return (
        <>
            <Header />
            <div
                ref={containerRef}
                style={{
                    position: isMobile ? 'relative' : 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    willChange: isMobile ? 'auto' : 'transform',
                    backfaceVisibility: isMobile ? 'visible' : 'hidden',
                    perspective: isMobile ? 'none' : 1000
                }}
            >
                <div ref={smoothScrollRef} className="default-view">
                    <AboutBannerView />
                    <AboutContent1View />
                    <AboutContent2View />
                    <AboutContent22View />
                    <AboutContent3View />
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default AboutView;
