import React from 'react';
import { Link } from 'react-router-dom';
import { Button, SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import AwardSurvivor from '@/assets/img/home/survivor/award_survivor@2x.webp';
import AwardSurvivorMobile from '@/assets/img/home/survivor/award_survivor_mobile@2x.webp';
import './home-content-survivor.view.less';
import { LocalStorageService } from '@/services';
import Tool from '@/components/tool/tool.jsx';

const HomeContent0View = () => {
    const localId = LocalStorageService.get('localId') || 'en-US';
    const logonText = {
        'en-US': require('@/assets/img/home/survivor/logo_text@2x.png'),
        'zh-CN': require('@/assets/img/home/survivor/logo_text_cn@2x.png')
    };

    return (
        <SectionContainer
            className="home-content-survivor-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/home/survivor/home_page_survivor_background@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/home/survivor/background.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className={`contents contents_${localId}`}>
                <h1 className="title">
                    <span className="logo-wrapper">
                        <img src={logonText[localId]} alt="" />
                    </span>
                    {/* <div className="new">
                        <img src={newImage} alt="" />
                    </div> */}
                    {/* <span className="text">
                        {formatMessage({ id: 'home.content0.name' })}
                    </span> */}
                </h1>
                <div className="google-play-wrapper google-play-wrapper-pc">
                    <img src={AwardSurvivor} alt="" />
                </div>
                <p className="desc">
                    {formatMessage({ id: 'home.content.survivor.description' })}
                </p>
                <div className="google-play-wrapper google-play-wrapper-mobile">
                    <img src={AwardSurvivorMobile} alt="" />
                </div>
                <Tool
                    appleurl="https://apps.apple.com/sg/app/survivor-io/id1528941310"
                    googleplayurl="https://play.google.com/store/apps/details?id=com.dxx.firenow"
                    detailPageId="survivor"
                />
                {/* <a
                    className="abutBtnIos"
                    target="_target"
                    href="https://apps.apple.com/sg/app/survivor-io/id1528941310"
                >
                    <img
                        className="jump-image"
                        src={require('./img/store.png')}
                        alt=""
                    />
                </a>
                <a
                    className="abutBtnA"
                    target="_target"
                    href="https://play.google.com/store/apps/details?id=com.dxx.firenow"
                >
                    <img
                        className="jump-image"
                        src={require('./img/play.png')}
                        alt=""
                    />
                </a>
                <Link to="/game/detail/survivor">
                    <Button className="abut">
                        {formatMessage({ id: 'home.content0.buttonText' })}
                    </Button>
                </Link> */}
            </div>
        </SectionContainer>
    );
};

export default HomeContent0View;
