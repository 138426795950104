/* eslint-disable import/prefer-default-export */
export { default as Api } from './api';

export const GAME_LIST = [
    {
        id: 'capybara',
        cardType: 'game',
        title: 'game.content1.game14.title',
        desc: 'game.content1.game14.desc',
        buttonText: 'game.content1.game14.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_capy@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-capybara/game_icon_capybara.png'),
        pcImg: require('@/assets/img/game/game_cover_capy.png'),
        pcIcon: require('@/assets/img/game-detail/game-capybara/game_icon_capybara.png'),
        isShow: true
    },
    {
        id: 'archero2',
        cardType: 'game',
        title: 'game.content1.game15.title',
        desc: 'game.content1.game15.desc',
        buttonText: 'game.content1.game15.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_archero2@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-archero2/game_icon_archero2.png'),
        pcImg: require('@/assets/img/game/game_cover_archero2.png'),
        pcIcon: require('@/assets/img/game-detail/game-archero2/game_icon_archero2.png'),
        isShow: true
    },
    {
        id: 'survivor',
        cardType: 'game',
        title: 'game.content1.game8.title',
        desc: 'game.content1.game8.desc',
        buttonText: 'game.content1.game8.buttonText',
        mobileImg: require('@/assets/img/game/Survivor@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-survivor/icon.png'),
        pcImg: require('@/assets/img/game/Survivor.png'),
        pcIcon: require('@/assets/img/game-detail/game-survivor/icon.png'),
        isShow: true
    },
    {
        id: 'archero',
        cardType: 'game',
        title: 'game.content1.game1.title',
        desc: 'game.content1.game1.desc',
        buttonText: 'game.content1.game1.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_archero@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-archero/archero-icon.png'),
        pcImg: require('@/assets/img/game/game_cover_archero.png'),
        pcIcon: require('@/assets/img/game-detail/game-archero/archero-icon@2x.png'),
        isShow: true
    },
    {
        id: 'souls',
        cardType: 'game',
        title: 'game.content1.game11.title',
        desc: 'game.content1.game11.desc',
        buttonText: 'game.content1.game11.buttonText',
        mobileImg: require('@/assets/img/game/souls@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-souls/icon.png'),
        pcImg: require('@/assets/img/game/souls.png'),
        pcIcon: require('@/assets/img/game-detail/game-souls/icon.png'),
        isShow: true
    },
    {
        id: 'snake',
        cardType: 'game',
        title: 'game.content1.game10.title',
        desc: 'game.content1.game10.desc',
        buttonText: 'game.content1.game10.buttonText',
        mobileImg: require('@/assets/img/game/snake@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-snake/icon.png'),
        pcImg: require('@/assets/img/game/snake.png'),
        pcIcon: require('@/assets/img/game-detail/game-snake/icon.png'),
        isShow: true
    },
    {
        id: 'punball',
        cardType: 'game',
        title: 'game.content1.game3.title',
        desc: 'game.content1.game3.desc',
        buttonText: 'game.content1.game3.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_punball@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-punball/icon.png'),
        pcImg: require('@/assets/img/game/game_cover_punball.png'),
        pcIcon: require('@/assets/img/game-detail/game-punball/icon@2x.png'),
        isShow: true
    },
    {
        id: 'penguin',
        cardType: 'game',
        title: 'game.content1.game2.title',
        desc: 'game.content1.game2.desc',
        buttonText: 'game.content1.game2.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_penguin@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-penguin/icon.png'),
        pcImg: require('@/assets/img/game/game_cover_penguin.png'),
        pcIcon: require('@/assets/img/game-detail/game-penguin/icon@2x.png'),
        isShow: true
    },
    {
        id: 'flamingCore',
        cardType: 'game',
        title: 'game.content1.game7.title',
        desc: 'game.content1.game7.desc',
        buttonText: 'game.content1.game7.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_flamingcore@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-core/icon.png'),
        pcImg: require('@/assets/img/game/game_cover_flamingcore.png'),
        pcIcon: require('@/assets/img/game-detail/game-core/icon@2x.png'),
        isShow: true
    },
    {
        id: 'slidey',
        cardType: 'game',
        title: 'game.content1.game4.title',
        desc: 'game.content1.game4.desc',
        buttonText: 'game.content1.game4.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_slidey@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-slidey/icon.png'),
        pcImg: require('@/assets/img/game/game_cover_slidey.png'),
        pcIcon: require('@/assets/img/game-detail/game-slidey/icon@2x.png'),
        isShow: true
    },
    {
        id: 'baseball',
        cardType: 'game',
        title: 'game.content1.game5.title',
        desc: 'game.content1.game5.desc',
        buttonText: 'game.content1.game5.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_baseball@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-baseball/icon.png'),
        pcImg: require('@/assets/img/game/game_cover_baseball.png'),
        pcIcon: require('@/assets/img/game-detail/game-baseball/icon@2x.png'),
        isShow: false
    },
    {
        id: 'spinRhythm',
        cardType: 'game',
        title: 'game.content1.game6.title',
        desc: 'game.content1.game6.desc',
        buttonText: 'game.content1.game6.buttonText',
        mobileImg: require('@/assets/img/game/game_cover_rhythm@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-rhythm/icon@2x.png'),
        pcImg: require('@/assets/img/game/game_cover_rhythm.png'),
        pcIcon: require('@/assets/img/game-detail/game-rhythm/icon.png'),
        isShow: false
    },
    {
        id: 'kinjaRun',
        cardType: 'game',
        title: 'game.content1.game9.title',
        desc: 'game.content1.game9.desc',
        buttonText: 'game.content1.game9.buttonText',
        mobileImg: require('@/assets/img/game/KinjaRun@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-kinjarun/icon.png'),
        pcImg: require('@/assets/img/game/KinjaRun.png'),
        pcIcon: require('@/assets/img/game-detail/game-kinjarun/icon.png'),
        isShow: true
    },
    {
        id: 'fishdom',
        cardType: 'game',
        title: 'game.content1.game13.title',
        desc: 'game.content1.game13.desc',
        buttonText: 'game.content1.game13.buttonText',
        mobileImg: require('@/assets/img/game/fishdom@768.png'),
        mobileIcon: require('@/assets/img/game-detail/game-rhythm/icon@2x.png'),
        pcImg: require('@/assets/img/game/fishdom.png'),
        pcIcon: require('@/assets/img/game-detail/game-rhythm/icon.png'),
        isShow: false
    },
    {
        id: 'comingsoon',
        cardType: 'comingsoon',
        title: 'game.content1.game12.title',
        isShow: true
    }
];
