import React from 'react';
import { Link } from 'react-router-dom';
import { formatMessage } from '@/locales';
import { Button, SectionContainer } from '@/components';
import './home-content-philosophy.view.less';

const HomeContent1View = () => {
    return (
        <SectionContainer
            className="home-content-philosophy-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/home/philosophy/home_page_philosophy_background@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/home/philosophy/background.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <Link to="/about">
                    <h1 className="title">
                        {formatMessage({ id: 'home.content1.title' })}
                    </h1>
                </Link>
                <p className="desc">
                    {formatMessage({ id: 'home.content1.description' })}
                </p>
                <Link to="/about">
                    <Button>
                        {formatMessage({ id: 'home.content1.buttonText' })}
                    </Button>
                </Link>
            </div>
        </SectionContainer>
    );
};

export default HomeContent1View;
