import { useEffect } from 'react';

/**
 * 页面加载时自动滚动到顶部的 Hook
 */
const useScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
};

export default useScrollToTop;
