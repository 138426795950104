/* eslint-disable global-require */
import ArcheroGameIcon from '@/assets/img/game-detail/game-archero/archero-icon@2x.png';
import PenguinGameIcon from '@/assets/img/game-detail/game-penguin/icon@2x.png';
import PunballGameIcon from '@/assets/img/game-detail/game-punball//icon@2x.png';
import SlideyGameIcon from '@/assets/img/game-detail/game-slidey/icon@2x.png';
import BaseballGameIcon from '@/assets/img/game-detail/game-baseball/icon@2x.png';
import RhythmGameIcon from '@/assets/img/game-detail/game-rhythm/icon@2x.png';
import CoreGameIcon from '@/assets/img/game-detail/game-core/icon@2x.png';
import survivorGameIcon from '@/assets/img/game-detail/game-survivor/icon.png';
import kinjaRunGameIcon from '@/assets/img/game-detail/game-kinjarun/icon.png';
import snakeGameIcon from '@/assets/img/game-detail/game-snake/icon.png';
import IndieIcon from '@/assets/img/game-detail/google-play-word-best-indie-game@2x.png';
import InnovativeIcon from '@/assets/img/game-detail/google-play-word-best-innovative-game@2x.png';
import Best2019 from '@/assets/img/game-detail/google-play-best-2019@2x.png';
import EditorChoice from '@/assets/img/game-detail/google-play-editor-choice@2x.png';

export default [
    {
        id: 'archero2',
        type: 'pic',
        downloadList: [
            {
                platform: 'ios',
                url: 'https://apps.apple.com/us/app/archero-2/id6502820653'
            },
            {
                platform: 'android',
                url: 'https://play.google.com/store/apps/details?id=com.xq.archeroii'
            }
        ],
        mediaList: [
            {
                platform: 'facebook',
                url: 'https://www.facebook.com/archero2/'
            },
            {
                platform: 'ins',
                url: 'https://www.instagram.com/archero2_official/'
            },
            { platform: 'x', url: 'https://x.com/Archero2_JP' }
        ],
        contentList: [
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/archero2_page_area01_img_en@2x.jpg'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/archero2_page_area01_img_cn@2x.jpg')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area01_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area01_img_cn@2x.png')
                    }
                },
                needDownload: true
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/archero2_page_area02_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/archero2_page_area02_img_cn@2x.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area02_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area02_img_cn@2x.png')
                    }
                }
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/archero2_page_area03_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/archero2_page_area03_img_cn@2x.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area03_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area03_img_cn@2x.png')
                    }
                }
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/archero2_page_area04_img_en@2x.jpg'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/archero2_page_area04_img_cn@2x.jpg')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area04_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-archero2/mobile_archero2_page_area04_img_cn@2x.png')
                    }
                },
                needDownload: true,
                needMedia: true
            }
        ]
    },
    {
        id: 'capybara',
        type: 'pic',
        downloadList: [
            {
                platform: 'ios',
                url: 'https://apps.apple.com/app/capybara-go/id6596787726'
            },
            {
                platform: 'android',
                url: 'https://play.google.com/store/apps/details?id=com.habby.capybara'
            }
        ],
        mediaList: [
            {
                platform: 'facebook',
                url: 'https://www.facebook.com/Capybara.Go'
            },
            {
                platform: 'ins',
                url: 'https://www.instagram.com/capybara_go_tw'
            },
            { platform: 'x', url: 'https://x.com/CapybaraGo_JP' }
        ],
        contentList: [
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/capy_page_area01_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/capy_page_area01_img_cn@2x.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area01_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area01_img_cn@2x.png')
                    }
                },
                needDownload: true
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/capy_page_area02_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/capy_page_area02_img_cn@2x.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area02_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area02_img_cn@2x.png')
                    }
                }
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/capy_page_area03_img_en@2x.jpg'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/capy_page_area03_img_cn@2x.jpg')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area03_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area03_img_cn@2x.png')
                    }
                }
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/capy_page_area04_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/capy_page_area04_img_cn@2x.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area04_img_en@2x.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-capybara/mobile_capy_page_area04_img_cn@2x.png')
                    }
                },
                needDownload: true,
                needMedia: true
            }
        ]
    },
    {
        id: 'souls',
        type: 'pic',
        downloadList: [
            {
                platform: 'ios',
                url: 'https://apps.apple.com/app/souls/id6448900613'
            },
            {
                platform: 'android',
                url: 'https://play.google.com/store/apps/details?id=com.habby.souls'
            },
            {
                platform: 'apk',
                url: 'https://applink.soulssvc.com/apk/souls.apk'
            }
        ],
        mediaList: [
            {
                platform: 'facebook',
                url: 'https://www.facebook.com/SOULS.official'
            },
            {
                platform: 'ins',
                url: 'https://www.instagram.com/souls_official_habby/'
            },
            { platform: 'x', url: 'https://twitter.com/Souls_US_' }
        ],
        contentList: [
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main1_en.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main1_zh.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main1_en_768-min.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main1_zh_768-min.png')
                    }
                },
                needDownload: true
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main2_en.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main2_zh.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main2_en_768-min.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main2_zh_768-min.png')
                    }
                }
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main3_en.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main3_zh.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main3_en_768-min.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main3_zh_768-min.png')
                    }
                }
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main4_en.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main4_zh.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main4_en_768-min.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main4_zh_768-min.png')
                    }
                }
            },
            {
                imageMap: {
                    default: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main5_en.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main5_zh.png')
                    },
                    768: {
                        'en-US': require('@/assets/img/game-detail/game-souls/main5_en_768-min.png'),
                        'zh-CN': require('@/assets/img/game-detail/game-souls/main5_zh_768-min.png')
                    }
                },
                needDownload: true,
                needMedia: true
            }
        ]
    },
    {
        id: 'archero',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-archero/banner.png'),
            768: require('@/assets/img/game-detail/game-archero/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/gb/app/archero/id1453651052',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.archero&hl=en&gl=US'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-archero/game-preview.png'),
                768: require('@/assets/img/game-detail/game-archero/game-preview@768.png')
            },
            icon: ArcheroGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-archero/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-archero/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'Become a Heroes!',
                'zh-CN': '战斗吧弓箭英雄！'
            },
            desc: {
                'en-US': `Enter the world of Archero, where existence itself is out to eliminate you! You are the only force to resist and defeat the never-ending waves of evil!\nSelect your favorite from the countless number of skills and run through clearing monster after monster in this exciting roguelike journey!`,
                'zh-CN': `黑暗侵袭了光明，邪恶吞噬了大地。弓箭英雄们，你的信念是最后的顽强，你的弓箭是最后的抵抗！吹响号角，为了心中那丝光明，射出击碎黑暗的命运之箭吧！\n请享受并妙用无穷无尽的技能组合，在这个令人兴奋的探险旅程里勇往直前，用弓箭将这些妖魔鬼怪全部击穿！`
            },
            googleBest: [IndieIcon, InnovativeIcon, Best2019]
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-archero/content-1.png'),
                    768: require('@/assets/img/game-detail/game-archero/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Countless number of random and unique skills to win.',
                        'Explore beautiful new worlds through hunters of maps.',
                        'Thousands of powerful monsters and obstacles.',
                        'Various new forms of gameplay including multiplayer.',
                        'All-new Monster Farm to grow your own Monsters.'
                    ],

                    'zh-CN': [
                        '无数的独特技能随机获得，每次探险大不同。',
                        '约过难关，不断探秘更多的弓箭世界。',
                        '千百种强大的怪物和障碍物，等你去征服。',
                        '新增多种新颖游戏玩法，包括多人模式等',
                        '全新的怪物农场，可以养成属于自己的怪物'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-archero/game-display-1.png'),
                    768: require('@/assets/img/game-detail/game-archero/game-display-1@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-archero/game-display-2.png'),
                    768: require('@/assets/img/game-detail/game-archero/game-display-2@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-archero/game-display-3.png'),
                    768: require('@/assets/img/game-detail/game-archero/game-display-3@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-archero/game-display-4.png'),
                    768: require('@/assets/img/game-detail/game-archero/game-display-4@768.png')
                }
            ],
            icon: ArcheroGameIcon,
            name: { 'en-US': 'Archero', 'zh-CN': '弓箭传说' },
            downloadUrl: {
                ios: 'https://apps.apple.com/gb/app/archero/id1453651052',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.archero&hl=en&gl=US'
            }
        }
    },
    {
        id: 'penguin',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-penguin/banner.png'),
            768: require('@/assets/img/game-detail/game-penguin/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/gb/app/penguin-isle/id1474314811',
                android:
                    'https://play.google.com/store/apps/details?id=com.fantome.penguinisle'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-penguin/preview.png'),
                768: require('@/assets/img/game-detail/game-penguin/preview@768.png')
            },
            icon: PenguinGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-penguin/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-penguin/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'CHILL WITH LOVELY PENGUINS!',
                'zh-CN': '与企鹅一起放松身心！'
            },
            desc: {
                'en-US': `Create your own Penguin Isle by collecting various and wonderful habitats. Enjoy by collecting cute penguins and decorating your own unique island with relaxing music.`,
                'zh-CN':
                    '创建多种企鹅栖息地并收集各种可爱的小企鹅吧，柔和平静的音乐和波涛声下，悠闲地打造属于你的企鹅岛！'
            },
            googleBest: [EditorChoice]
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-penguin/content-1.png'),
                    768: require('@/assets/img/game-detail/game-penguin/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'An idle game with healing and relaxing gameplay.',
                        'Collection of all the Penguins around the world.',
                        'Comforting and peaceful sound of waves and BGMs.',
                        'Arctic lifestyle with Creatures and more.',
                        'Decorate your own Island.',
                        'Wide range of mini-games to enjoy.'
                    ],
                    'zh-CN': [
                        '简单的玩法，轻松愉快的操作，自带治愈功能',
                        '收集各种可爱的小企鹅',
                        '背景声浪和温馨，直抵心灵深处',
                        '神秘极地世界的故事，在这里徐徐展开',
                        '装扮属于你自己的企鹅岛',
                        '创建属于你的悠闲空间，享受一段冰雪世界的治愈之旅！'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-penguin/display-1.png'),
                    768: require('@/assets/img/game-detail/game-penguin/display-1@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-penguin/display-2.png'),
                    768: require('@/assets/img/game-detail/game-penguin/display-2@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-penguin/display-3.png'),
                    768: require('@/assets/img/game-detail/game-penguin/display-3@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-penguin/display-4.png'),
                    768: require('@/assets/img/game-detail/game-penguin/display-4@768.png')
                }
            ],
            icon: PenguinGameIcon,
            name: {
                'en-US': 'Penguin Isle',
                'zh-CN': '企鹅之岛'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/gb/app/penguin-isle/id1474314811',
                android:
                    'https://play.google.com/store/apps/details?id=com.fantome.penguinisle'
            }
        }
    },
    {
        id: 'punball',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-punball/banner.png'),
            768: require('@/assets/img/game-detail/game-punball/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/punball/id1585781366',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.punball&hl=en_GB&gl=US'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-punball/preview.png'),
                768: require('@/assets/img/game-detail/game-punball/preview@768.png')
            },
            icon: PunballGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-punball/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-punball/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'AIM, SHOOT, BOUNCE!',
                'zh-CN': '瞄准！射击！跳跃！'
            },
            desc: {
                'en-US': `A lone mage, pretty and strong, stands alone as the only savior in a quest for light over darkness. Follow her on this Roguelike adventure; don’t lose though or you’ll have to begin from scratch again!
                `,
                'zh-CN':
                    '一位注定与孤单相伴的法师，在窒息的夜中，决然前行！作为黑暗世界的唯一救世主，砰砰法师咬紧牙关，以坚强为甲，用热血铸刃，高举手中的魔法之球，去照亮整个世界吧！各位英雄们，伟大的旅程已经开始，将恐惧撕碎，跟随我们的孤胆法师，用勇气和魔法，让光明遍布天地！'
            },
            googleBest: [EditorChoice]
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-punball/content-1.png'),
                    768: require('@/assets/img/game-detail/game-punball/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Spin-off from Archero’s IP, opening an all-new form of adventure',
                        `First Roguelite + RPG combined with Block Breaker Gameplay`,
                        'Easy to play, hard to master!',
                        '100+ Skills with infinite combinations!',
                        'Thousands of stages!'
                    ],
                    'zh-CN': [
                        '全球爆款《弓箭传说》的衍生作品，开启全新冒险形式！',
                        '首个结合Roguelite + RPG玩法的弹球游戏！',
                        '上手容易精通难，挑战性十足！',
                        '100+技能随机组合，拥有无限可能性！',
                        '无限关卡等你来挑战！'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-punball/display-1.png'),
                    768: require('@/assets/img/game-detail/game-punball/display-1@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-punball/display-2.png'),
                    768: require('@/assets/img/game-detail/game-punball/display-2@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-punball/display-3.png'),
                    768: require('@/assets/img/game-detail/game-punball/display-3@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-punball/display-4.png'),
                    768: require('@/assets/img/game-detail/game-punball/display-4@768.png')
                }
            ],
            icon: PunballGameIcon,
            name: {
                'en-US': 'PunBall',
                'zh-CN': '砰砰法师'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/punball/id1585781366',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.punball&hl=en_GB&gl=US'
            }
        }
    },
    {
        id: 'slidey',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-slidey/banner.png'),
            768: require('@/assets/img/game-detail/game-slidey/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/gb/app/slidey-block-puzzle/id966322463',
                android:
                    'https://play.google.com/store/apps/details?id=com.mavis.slidey'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-slidey/preview.png'),
                768: require('@/assets/img/game-detail/game-slidey/preview@768.png')
            },
            icon: SlideyGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-slidey/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-slidey/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'INNOVATIVE PUZZLE GAME!',
                'zh-CN': '消除游戏的颠覆之作！'
            },
            desc: {
                'en-US': `Slidey is a brand new puzzle game that’s addictive and super easy to play! With unique gameplay and endless fun, all you have to do is slide the blocks and clear the screen!`,
                'zh-CN':
                    '这是一款上手轻松的全新消除游戏。滑动方块，凑集一行即可消除。独一无二的玩法，停不下来的乐趣，让你将所有烦恼全部消除！'
            },
            googleBest: []
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-slidey/content-1.png'),
                    768: require('@/assets/img/game-detail/game-slidey/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'An all new brain puzzle game that you can play for hours! · Experience truly endless challenges.',
                        `Use Boosters in-game to help you on your journey.`,
                        'Grow your Tribe and experience various worlds to help you.',
                        'Complete Quests to collect items and win!'
                    ],
                    'zh-CN': [
                        '全新玩法，欲罢不能！',
                        '无限挑战，体验升级，快乐沉浸其中',
                        '使用道具让你的挑战更轻松！',
                        '收集方块，和小萨满一起建造美丽的村庄',
                        '完成收集任务，获得游戏胜利'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-slidey/display-1.png'),
                    768: require('@/assets/img/game-detail/game-slidey/display-1@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-slidey/display-2.png'),
                    768: require('@/assets/img/game-detail/game-slidey/display-2@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-slidey/display-3.png'),
                    768: require('@/assets/img/game-detail/game-slidey/display-3@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-slidey/display-4.png'),
                    768: require('@/assets/img/game-detail/game-slidey/display-4@768.png')
                }
            ],
            icon: SlideyGameIcon,
            name: {
                'en-US': 'Slidey®: Block Puzzle',
                'zh-CN': '萨满爱消除'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/gb/app/slidey-block-puzzle/id966322463',
                android:
                    'https://play.google.com/store/apps/details?id=com.mavis.slidey'
            }
        }
    },
    {
        id: 5,
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-baseball/banner.png'),
            768: require('@/assets/img/game-detail/game-baseball/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/super-hit-baseball/id1473199423',
                android:
                    'https://play.google.com/store/apps/details?id=com.offfgames.android.free.baseballbattle&hl=en_GB&gl=US'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-baseball/preview.png'),
                768: require('@/assets/img/game-detail/game-baseball/preview@768.png')
            },
            icon: BaseballGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-baseball/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-baseball/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'MULTIPLAYER BASEBALL MATCHES!',
                'zh-CN': '超爽的棒球比赛'
            },
            desc: {
                'en-US': `Enjoy super fun and casual baseball matches in Super Hit Baseball. Build your perfect dream team and take them to success in this arcade version of baseball! `,
                'zh-CN':
                    '超级棒球是一款非常好玩的休闲棒球游戏。玩家可在游戏中可以招募近百位强力球员，组建属于自己的梦之队，并进行真人PVP对决，获得超强刺激的打击感和成就感！'
            },
            googleBest: []
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-baseball/content-1.png'),
                    768: require('@/assets/img/game-detail/game-baseball/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Quick and casual PVP baseball matches!',
                        `More than 100+ collectible player cards - collect them all!`,
                        '15+ Stadiums that reflect real places around the world',
                        'Build your dream team to take on your friends and others',
                        'Interact with your Club and strive to be the best Club!'
                    ],
                    'zh-CN': [
                        '简单易上手！快速比赛的棒球游戏，感受超强打击感',
                        '可收集近百位超级明星棒球选手',
                        '超过15个现实著名体育场场景，体验更真实',
                        '号召好友组建强大俱乐部',
                        '在竞争中取胜，成为最强大俱乐部'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-baseball/display-1.png'),
                    768: require('@/assets/img/game-detail/game-baseball/display-1@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-baseball/display-2.png'),
                    768: require('@/assets/img/game-detail/game-baseball/display-2@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-baseball/display-3.png'),
                    768: require('@/assets/img/game-detail/game-baseball/display-3@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-baseball/display-4.png'),
                    768: require('@/assets/img/game-detail/game-baseball/display-4@768.png')
                }
            ],
            icon: BaseballGameIcon,
            name: {
                'en-US': 'Super Hit Baseball',
                'zh-CN': '超级棒球'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/super-hit-baseball/id1473199423',
                android:
                    'https://play.google.com/store/apps/details?id=com.offfgames.android.free.baseballbattle&hl=en_GB&gl=US'
            }
        }
    },
    {
        id: 'spinRhythm',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-rhythm/banner.png'),
            768: require('@/assets/img/game-detail/game-rhythm/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/spin-rhythm/id1560618669',
                android:
                    'https://play.google.com/store/apps/details?id=com.ssdgame.spinrhythm&hl=en_GB&gl=US'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-rhythm/preview.png'),
                768: require('@/assets/img/game-detail/game-rhythm/preview@768.png')
            },
            icon: RhythmGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-rhythm/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-rhythm/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'FUNKY BEATS & CHALLENGES',
                'zh-CN': '炫酷的音乐挑战'
            },
            desc: {
                'en-US': `Spin Rhythm is a new and innovative game in the music space. Match the colors to the rhythm in this beautiful music game! Be a DJ, collect new tracks and grow your own music presence! `,
                'zh-CN':
                    '旋转节奏是一款全新原创的音乐游戏。旋转, 匹配, 点击的玩法与音乐结合,打碟般的体验+嗨翻全场的电音, 让我们一起在音乐的世界中燥起来！'
            },
            googleBest: []
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-rhythm/content-1.png'),
                    768: require('@/assets/img/game-detail/game-rhythm/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Original and unique music gameplay',
                        `Super cool and funky visuals`,
                        '30+ fully licensed EDM tracks',
                        '3 different difficulty levels to challenge',
                        'Mix random tracks in Career mode to experience your own new music'
                    ],
                    'zh-CN': [
                        '原创的独特游戏玩法',
                        '超酷又时髦的视觉效果',
                        '拥有30+首非常热门的电子音乐歌曲, 并且已获得授权',
                        '每首歌精心设计了3种挑战难度, 让玩家轻松上手的同时还可以持续挑战自我',
                        '生涯模式随机组合不同的歌曲, 让玩家拥有自己的演奏舞台'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-rhythm/display-1.png'),
                    768: require('@/assets/img/game-detail/game-rhythm/display-1@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-rhythm/display-2.png'),
                    768: require('@/assets/img/game-detail/game-rhythm/display-2@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-rhythm/display-3.png'),
                    768: require('@/assets/img/game-detail/game-rhythm/display-3@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-rhythm/display-4.png'),
                    768: require('@/assets/img/game-detail/game-rhythm/display-4@768.png')
                }
            ],
            icon: RhythmGameIcon,
            name: {
                'en-US': 'Spin Rhythm',
                'zh-CN': '旋转节奏'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/spin-rhythm/id1560618669',
                android:
                    'https://play.google.com/store/apps/details?id=com.ssdgame.spinrhythm&hl=en_GB&gl=US'
            },
            big: true
        }
    },
    {
        id: 'flamingCore',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-core/banner.png'),
            768: require('@/assets/img/game-detail/game-core/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/flaming-core/id1273108688',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.flamingcore&hl=en_GB&gl=US'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-core/preview.png'),
                768: require('@/assets/img/game-detail/game-core/preview@768.png')
            },
            icon: CoreGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-core/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-core/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'BULLET-TIME ACTION!',
                'zh-CN': '战斗吧子弹！'
            },
            desc: {
                'en-US': `Control your Core to defeat enemies and get to the end: the only way to restore order and save the world. All your comrades have been hacked and now everything out there has only one mission: to obliterate you. Swipe and slide to clean the world!`,
                'zh-CN':
                    '生死与共的战友已被异化，曾经美好的世界也开始残缺。作为这个世界的唯一幸存者，你需要躲开所有的枪林弹雨，射出复仇的子弹，击碎诡异世界的荒谬，让爱和秩序重回人间！开始战斗吧，子弹！'
            },
            googleBest: []
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-core/content-1.png'),
                    768: require('@/assets/img/game-detail/game-core/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Easy  to play, swipe and slide!',
                        `Slow down time with bullet-time action!`,
                        'Bounce off walls and enemies to create your perfect attack',
                        'Over 160 Levels across different worlds!'
                    ],
                    'zh-CN': [
                        '单手即可操作，简单易上手！',
                        '科幻风格无比炫酷！',
                        '选择角度创造完美攻击，享受弹射快感！',
                        '跨越不同世界的 160 多个关卡等你来挑战！'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-core/display-1.png'),
                    768: require('@/assets/img/game-detail/game-core/display-1@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-core/display-2.png'),
                    768: require('@/assets/img/game-detail/game-core/display-2@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-core/display-3.png'),
                    768: require('@/assets/img/game-detail/game-core/display-3@768.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-core/display-4.png'),
                    768: require('@/assets/img/game-detail/game-core/display-4@768.png')
                }
            ],
            icon: CoreGameIcon,
            name: {
                'en-US': 'Flaming Core',
                'zh-CN': '赤核'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/us/app/flaming-core/id1273108688',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.flamingcore&hl=en_GB&gl=US'
            }
        }
    },
    {
        id: 'survivor',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-survivor/banner.png'),
            768: require('@/assets/img/game-detail/game-survivor/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/sg/app/survivor-io/id1528941310',
                android:
                    'https://play.google.com/store/apps/details?id=com.dxx.firenow'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-survivor/game-preview.png'),
                768: require('@/assets/img/game-detail/game-survivor/game-preview.png')
            },
            icon: survivorGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-survivor/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-survivor/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'Survive the Monsters, Survive the Fun!',
                'zh-CN': '活屍大軍的末日'
            },
            desc: {
                'en-US': `Dangerous zombies are attacking the entire city! The city is in peril! Awakened by the trial of dreams, you've no choice but to take on the heroic mantle of saving the city! As a human warrior with unlimited potential, you and other survivors will have to pick up your weapons and battle these evil and dangerous zombies!`,
                'zh-CN':
                    '为了生存！燃烧你的手指，燃烧千万怪兽军团！一款Roguelike、求生、割草完美结合的游戏不仅有末路求生的刺激爽快感，Roguelike超新鲜感体验，还融入了更多特别有趣玩法。'
            },
            googleBest: []
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-survivor/content-1.png'),
                    768: require('@/assets/img/game-detail/game-survivor/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Face off against 1000+ monsters at once and exterminate them!',
                        `Clear the map with one-hand controls!`,
                        'All-new roguelite skill experience with unlimited combinations!',
                        'Feel the heat of each new stage with different difficulties!'
                    ],
                    'zh-CN': [
                        '1000+怪物同屏，消灭他们！',
                        '单手操作，称霸地图！',
                        '全新Roguelite技能体验，无限组合',
                        '全新关卡体验，多重难度选择'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-survivor/game-display-1@2x.png'),
                    768: require('@/assets/img/game-detail/game-survivor/game-display-1@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-survivor/game-display-2@2x.png'),
                    768: require('@/assets/img/game-detail/game-survivor/game-display-2@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-survivor/game-display-3@2x.png'),
                    768: require('@/assets/img/game-detail/game-survivor/game-display-3@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-survivor/game-display-4@2x.png'),
                    768: require('@/assets/img/game-detail/game-survivor/game-display-4@2x.png')
                }
            ],
            icon: survivorGameIcon,
            name: {
                'en-US': 'Survivor',
                'zh-CN': '弹壳特攻队'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/sg/app/survivor-io/id1528941310',
                android:
                    'https://play.google.com/store/apps/details?id=com.dxx.firenow'
            }
        }
    },
    {
        id: 'kinjaRun',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-kinjarun/banner.png'),
            768: require('@/assets/img/game-detail/game-kinjarun/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/sg/app/kinja-run/id1625065102',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.kinjarun'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-kinjarun/game-preview.png'),
                768: require('@/assets/img/game-detail/game-kinjarun/game-preview.png')
            },
            icon: kinjaRunGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-kinjarun/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-kinjarun/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'A brave Ninja cat！',
                'zh-CN': '當下最火爆的跑酷遊戲！'
            },
            desc: {
                'en-US': `A brave Ninja cat, running into a path full of monsters! The world’s first runner and shoot-em-up! Run and dodge missiles!`,
                'zh-CN':
                    '当下最火爆的跑酷游戏，跑酷、战斗、射击、弹幕、Roguelike 应有尽有，带给你完全不同的跑酷体验。挑战你的反应极限，秀出你的神仙走位，赶快下载《酷喵跑酷》！'
            },
            googleBest: []
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-kinjarun/content-1.png'),
                    768: require('@/assets/img/game-detail/game-kinjarun/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Run and Auto Battle',
                        `No extra controls`,
                        'Easy to play, hard to master',
                        'Roguelike Runner',
                        'Shoot-em-up',
                        'RPG Progression'
                    ],
                    'zh-CN': [
                        '跑酷与自动战斗完美结合，拥有丝滑般的战斗体验。',
                        '极简操作，一只手指就能玩。',
                        '全球首创Roguelite跑酷体验。',
                        '千变万化的弹幕设计，让你心流达到巅峰。',
                        '可爱的猫咪变身忍者，完全不一样的世界观。'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-kinjarun/game-display-1@2x.png'),
                    768: require('@/assets/img/game-detail/game-kinjarun/game-display-1@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-kinjarun/game-display-2@2x.png'),
                    768: require('@/assets/img/game-detail/game-kinjarun/game-display-2@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-kinjarun/game-display-3@2x.png'),
                    768: require('@/assets/img/game-detail/game-kinjarun/game-display-3@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-kinjarun/game-display-4@2x.png'),
                    768: require('@/assets/img/game-detail/game-kinjarun/game-display-4@2x.png')
                }
            ],
            icon: kinjaRunGameIcon,
            name: {
                'en-US': 'Kinja Run',
                'zh-CN': '酷喵跑酷'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/sg/app/kinja-run/id1625065102',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.kinjarun'
            }
        }
    },
    {
        id: 'snake',
        template: 'pic-text',
        bannerImage: {
            default: require('@/assets/img/game-detail/game-snake/banner.png'),
            768: require('@/assets/img/game-detail/game-snake/banner@768.png')
        },
        game: {
            downloadUrl: {
                ios: 'https://apps.apple.com/app/sssnaker/id1595070036',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.sssnaker'
            },
            previewImage: {
                default: require('@/assets/img/game-detail/game-snake/game-preview.png'),
                768: require('@/assets/img/game-detail/game-snake/game-preview.png')
            },
            icon: snakeGameIcon,
            logonText: {
                'en-US': require('@/assets/img/game-detail/game-snake/logo_text@2x.png'),
                'zh-CN': require('@/assets/img/game-detail/game-snake/logo_text_cn@2x.png')
            },
            title: {
                'en-US': 'Slither, snack, shoot! ',
                'zh-CN': '蛇蛇贪吃趴趴走，Rogue升级打怪兽！'
            },
            desc: {
                'en-US': `A Snake X Bullet Hell roguelite shooter that no one's ever seen! Grow to ginormous proportions and blast your enemies to kingdom come! It's Sssnaker! In the dangerous dungeon depths, your snake won't just grow longer, it'll also get stronger!`,
                'zh-CN':
                    '从未见过的 Snake X Bullet Hell 流氓射击游戏！成长到巨大的比例，将敌人炸成王国！这是 SSSnaker！在危险的地牢深处，您的蛇不仅会变长，还会变得更强壮！'
            },
            googleBest: []
        },
        contents: [
            {
                image: {
                    default: require('@/assets/img/game-detail/game-snake/content-1.png'),
                    768: require('@/assets/img/game-detail/game-snake/content-1@768.png')
                },
                title: {
                    'en-US': 'Features',
                    'zh-CN': '游戏特色'
                },
                infos: {
                    'en-US': [
                        'Sensational fun with silky smooth snake slithering with unique area attacks',
                        'All-new roguelite skills and snake head collision feature for that strategic spark',
                        `Myriad monsters and game features that'll test your skill limits`,
                        'Kaleidoscopic bullet hell for maximum sssnaker intensity!'
                    ],
                    'zh-CN': [
                        '丝般顺滑的蛇滑行和独特的区域攻击，带来轰动的乐趣',
                        '全新的 Roguelite 技能和蛇头碰撞功能，带来战略火花',
                        '无数的怪物和游戏功能，将测试你的技能极限',
                        '万花筒般的子弹地狱，实现最大的 SSSnaker 强度！'
                    ]
                }
            }
        ],
        gameDisplay: {
            images: [
                {
                    default: require('@/assets/img/game-detail/game-snake/game-display-1@2x.png'),
                    768: require('@/assets/img/game-detail/game-snake/game-display-1@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-snake/game-display-2@2x.png'),
                    768: require('@/assets/img/game-detail/game-snake/game-display-2@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-snake/game-display-3@2x.png'),
                    768: require('@/assets/img/game-detail/game-snake/game-display-3@2x.png')
                },
                {
                    default: require('@/assets/img/game-detail/game-snake/game-display-4@2x.png'),
                    768: require('@/assets/img/game-detail/game-snake/game-display-4@2x.png')
                }
            ],
            icon: snakeGameIcon,
            name: {
                'en-US': 'SSSnaker',
                'zh-CN': '蛇蛇贪吃'
            },
            downloadUrl: {
                ios: 'https://apps.apple.com/app/sssnaker/id1595070036',
                android:
                    'https://play.google.com/store/apps/details?id=com.habby.sssnaker'
            }
        }
    }
];
