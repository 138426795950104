import React from 'react';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import './home-content-archero2.view.less';
import { LocalStorageService } from '@/services';
import Tool from '@/components/tool/tool';

const HomeContentArchero2 = () => {
    const localId = LocalStorageService.get('localId') || 'en-US';
    const logonText = {
        'en-US': require('@/assets/img/home/archero2/archero2_logo_en@2x.png'),
        'zh-CN': require('@/assets/img/home/archero2/archero2_logo_cn@2x.png')
    };

    return (
        <SectionContainer
            className="home-content-archero2-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/home/archero2/home_page_archero2_background_m@2x.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/home/archero2/home_page_archero2_background@2x.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            {/* <div className="new">
                <img src={newImage} alt="" />
            </div> */}
            <div className="contents">
                <div className="title">
                    <span className="logo-wrapper">
                        <img src={logonText[localId]} alt="" />
                    </span>
                    
                    {/* <span className="text">
                        {formatMessage({ id: 'home.content0.name' })}
                    </span> */}
                </div>
                {/* <div className="google-play-wrapper">
                    <img src={GooglePlayImage} alt="" />
                </div> */}
                <p className="desc">
                    {formatMessage({ id: 'home.content.archero2.description' })}
                </p>
                <Tool
                    appleurl="https://apps.apple.com/us/app/archero-2/id6502820653"
                    googleplayurl="https://play.google.com/store/apps/details?id=com.xq.archeroii"
                    detailPageId="archero2"
                />
            </div>
        </SectionContainer>
    );
};

export default HomeContentArchero2;
