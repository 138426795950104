/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '@/components';
import { LocalStorageService } from '@/services';

import GameDetailData from './game-detail-data';

import PicTextGameDetail from './template/pic-text/index.view';

import GameSouls from './souls/index.view';

const GameDetailView = () => {
    const { id } = useParams();
    document.documentElement.setAttribute('game-name', id);
    const [data] = GameDetailData.filter(item => item.id == id);

    const localId = LocalStorageService.get('localId') || 'en-US';

    const containerRef = useRef(null);
    const smoothScrollRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const updateHeight = () => {
            if (!smoothScrollRef.current) return;
            
            requestAnimationFrame(() => {
                const container = smoothScrollRef.current;
                const rect = container.getBoundingClientRect();
                const totalHeight = Math.ceil(rect.height) - 2;
                
                document.body.style.height = `${totalHeight}px`;
                
                if (process.env.NODE_ENV === 'development') {
                    console.log('Game detail page height:', {
                        totalHeight,
                        rectHeight: rect.height
                    });
                }
            });
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        
        const resizeObserver = new ResizeObserver(updateHeight);
        if (smoothScrollRef.current) {
            resizeObserver.observe(smoothScrollRef.current);
        }

        return () => {
            window.removeEventListener('resize', updateHeight);
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <>
            <Header />
            <div ref={smoothScrollRef}>
                {data.type == 'pic' ? (
                    <div className="default-view">
                        <GameSouls data={data} localId={localId} id={id} />
                    </div>
                ) : (
                    <PicTextGameDetail data={data} localId={localId} id={id} />
                )}
            </div>
        </>
    );
};

export default GameDetailView;
