/* eslint-disable react/prop-types */
import React from 'react';
import { SectionContainer, Footer } from '@/components';

import './index.view.less';

const GameSouls = props => {
    const { data, localId, id } = props;

    const { contentList, downloadList, mediaList } = data || {};

    function handleJumpUrl(url) {
        window.open(url);
    }

    return (
        <SectionContainer className="game-detail-souls">
            {contentList.map((item, index) => {
                const { imageMap, needDownload, needMedia } = item;
                return (
                    <div className={`view view${index + 1}`}>
                        <picture>
                            {Object.entries(imageMap).map(([key, value]) => {
                                if (key === 'default') {
                                    return (
                                        <img
                                            key={key}
                                            src={value[localId]}
                                            alt=""
                                        />
                                    );
                                }
                                return (
                                    <source
                                        key={key}
                                        srcSet={value[localId]}
                                        media={`(max-width: ${key}px)`}
                                    />
                                );
                            })}
                        </picture>
                        {needDownload && downloadList.length > 0 && (
                            <div className="downloadContent">
                                {downloadList.map(i => (
                                    <div
                                        className="downloadItem"
                                        onClick={handleJumpUrl.bind(
                                            this,
                                            i.url
                                        )}
                                    />
                                ))}
                            </div>
                        )}
                        {needMedia && mediaList.length > 0 && (
                            <div className="mediaContent">
                                {mediaList.map(i => (
                                    <div
                                        className="mediaItem"
                                        onClick={handleJumpUrl.bind(
                                            this,
                                            i.url
                                        )}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
            {/* <div className="view1">
                <picture>
                    {Object.entries(main1).map(([key, value]) => {
                        if (key === 'default') {
                            return (
                                <img key={key} src={value[localId]} alt="" />
                            );
                        }
                        return (
                            <source
                                key={key}
                                srcSet={value[localId]}
                                media={`(max-width: ${key}px)`}
                            />
                        );
                    })}
                </picture>
                <div className="downClass">
                    {downloadUrl.ios && (
                        <a href={downloadUrl.ios} target="_blank" />
                    )}
                    {downloadUrl.android && (
                        <a href={downloadUrl.android} target="_blank" />
                    )}
                    {downloadUrl.apk && (
                        <a href={downloadUrl.apk} target="_blank" />
                    )}
                </div>
            </div>
            <picture>
                {Object.entries(main2).map(([key, value]) => {
                    if (key === 'default') {
                        return <img key={key} src={value[localId]} alt="" />;
                    }
                    return (
                        <source
                            key={key}
                            srcSet={value[localId]}
                            media={`(max-width: ${key}px)`}
                        />
                    );
                })}
            </picture>
            <picture>
                {Object.entries(main3).map(([key, value]) => {
                    if (key === 'default') {
                        return <img key={key} src={value[localId]} alt="" />;
                    }
                    return (
                        <source
                            key={key}
                            srcSet={value[localId]}
                            media={`(max-width: ${key}px)`}
                        />
                    );
                })}
            </picture>
            {main4 && (
                <picture>
                    {Object.entries(main4).map(([key, value]) => {
                        if (key === 'default') {
                            return (
                                <img key={key} src={value[localId]} alt="" />
                            );
                        }
                        return (
                            <source
                                key={key}
                                srcSet={value[localId]}
                                media={`(max-width: ${key}px)`}
                            />
                        );
                    })}
                </picture>
            )}
            {main5 && (
                <div className="view5">
                    <picture>
                        {Object.entries(main5).map(([key, value]) => {
                            if (key === 'default') {
                                return (
                                    <img
                                        key={key}
                                        src={value[localId]}
                                        alt=""
                                    />
                                );
                            }
                            return (
                                <source
                                    key={key}
                                    srcSet={value[localId]}
                                    media={`(max-width: ${key}px)`}
                                />
                            );
                        })}
                    </picture>
                    <div className="Wrap">
                        <div className="downClass">
                            <a href={downloadUrl.ios} target="_blank" />
                            <a href={downloadUrl.android} target="_blank" />
                            <a href={downloadUrl.apk} target="_blank" />
                        </div>
                        <div className="mediaClass">
                            <a href={media.Facebook} target="_blank" />
                            <a href={media.Ins} target="_blank" />
                            <a href={media.Twitter} target="_blank" />
                        </div>
                    </div>
                </div>
            )} */}
            <Footer />
        </SectionContainer>
        // <SectionContainer className="game-detail-content-1-view">
        //     <div className={ id == 6? 'contentAspin': 'content' }>
        //         <div className="left">
        //             <picture>
        //                 {Object.entries(previewImage).map(([key, value]) => {
        //                     if (key === 'default') {
        //                         return <img key={key} src={value} alt="" />;
        //                     }
        //                     return (
        //                         <source
        //                             key={key}
        //                             srcSet={value}
        //                             media={`(max-width: ${key}px)`}
        //                         />
        //                     );
        //                 })}
        //             </picture>
        //         </div>
        //         <div className="right">
        //             <div className="icon-row">
        //                 <div className="icon-wrapper">
        //                     <img className="icon-logo" src={icon} alt="" />
        //                 </div>
        //                 <div className="icon-logo-text-wrapper">
        //                     <img
        //                         className="icon-logo-text"
        //                         src={logonText[localId]}
        //                         alt=""
        //                     />
        //                 </div>
        //             </div>

        //             <h2 className="title">{title[localId]}</h2>
        //             <p className="desc">{desc[localId]}</p>
        //             <div className="platfom-download">
        //                 <div className="appstore-icon">
        //                     <a href={downloadUrl.ios} target="_blank">
        //                         <img src={AppStoreIcon} alt="" />
        //                     </a>
        //                 </div>
        //                 <div className="google-play-icon">
        //                     <a href={downloadUrl.android} target="_blank">
        //                         <img src={GooglePlayIcon} alt="" />
        //                     </a>
        //                 </div>
        //             </div>
        //             <ul className="google-play-best">
        //                 {googleBest?.map(image => (
        //                     <li className="googleplay-best-icon" key={image}>
        //                         <img src={image} alt="" />
        //                     </li>
        //                 ))}
        //             </ul>
        //         </div>
        //     </div>
        // </SectionContainer>
    );
};

export default GameSouls;
