/* eslint-disable react/prop-types */
import React from 'react';
import { SectionContainer } from '@/components';
import AppStoreIcon from '@/assets/img/game-detail/appstore_download_icon@2x.png';
import GooglePlayIcon from '@/assets/img/game-detail/googleplay_icon@2x.png';

import './game-detail-content-1.view.less';

const GameDetailContent1View = ({ data, localId, id}) => {
    const {
        previewImage,
        icon,
        logonText = {},
        title = {},
        desc = {},
        downloadUrl = {},
        googleBest,
        big
    } = data;
    
    return (
        <SectionContainer className="game-detail-content-1-view">
            <div className={ id == 6? 'contentAspin': 'content' }>
                <div className="left">
                    <picture>
                        {Object.entries(previewImage).map(([key, value]) => {
                            if (key === 'default') {
                                return <img key={key} src={value} alt="" />;
                            }
                            return (
                                <source
                                    key={key}
                                    srcSet={value}
                                    media={`(max-width: ${key}px)`}
                                />
                            );
                        })}
                    </picture>
                </div>
                <div className="right">
                    <div className="icon-row">
                        <div className="icon-wrapper">
                            <img className="icon-logo" src={icon} alt="" />
                        </div>
                        <div className="icon-logo-text-wrapper">
                            <img
                                className="icon-logo-text"
                                src={logonText[localId]}
                                alt=""
                            />
                        </div>
                    </div>

                    <h2 className="title">{title[localId]}</h2>
                    <p className="desc">{desc[localId]}</p>
                    <div className="platfom-download">
                        <div className="appstore-icon">
                            <a href={downloadUrl.ios} target="_blank">
                                <img src={AppStoreIcon} alt="" />
                            </a>
                        </div>
                        <div className="google-play-icon">
                            <a href={downloadUrl.android} target="_blank">
                                <img src={GooglePlayIcon} alt="" />
                            </a>
                        </div>
                    </div>
                    <ul className="google-play-best">
                        {googleBest?.map(image => (
                            <li className="googleplay-best-icon" key={image}>
                                <img src={image} alt="" />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </SectionContainer>
    );
};

export default GameDetailContent1View;
